<div class="asc-area-side-panel asc-menu">
   <h3 class="asc-menu-section">Einstellungen</h3>
   <mat-nav-list class="fx-flex">
      <div *ngFor="let settingsNavigationItem of settingsNavigationItems">
         <div *ngIf="settingsNavigationItem.entryPoints.length > 1; then multiEntryPoints else singleEntryPoint"></div>
         <ng-template #multiEntryPoints>
            <mat-list-item [matMenuTriggerFor]="menu">
               <asc-module-icon matListItemIcon [primary]="settingsNavigationItem.icons.primary"
                  [secondary]="settingsNavigationItem.icons.secondary"></asc-module-icon>
               <span matListItemLine>{{settingsNavigationItem.displayName}}</span>
            </mat-list-item>
            <mat-menu #menu="matMenu" [overlapTrigger]="true">
               <button mat-menu-item (click)="navigateTo(entryPoint)"
                  *ngFor="let entryPoint of settingsNavigationItem.entryPoints">
                  <asc-module-icon [primary]="entryPoint.icons?.primary" [secondary]="entryPoint.icons?.secondary"
                     size="sm"></asc-module-icon>
                  {{entryPoint.displayName}}
               </button>
            </mat-menu>
         </ng-template>
         <ng-template #singleEntryPoint>
            <mat-list-item (click)="navigateTo(settingsNavigationItem.entryPoints[0])">
               <asc-module-icon matListItemIcon [primary]="settingsNavigationItem.icons.primary"
                  [secondary]="settingsNavigationItem.icons.secondary"></asc-module-icon>
               <span matListItemLine>{{settingsNavigationItem.displayName}}</span>
            </mat-list-item>
         </ng-template>
      </div>
   </mat-nav-list>
</div>