import { HttpParams } from '@angular/common/http';

export class InterceptorHttpParams extends HttpParams {
  constructor(
    public interceptorConfig: { cacheRequest: boolean },
    params?: { [param: string]: string | string[] }
  ) {
    super({ fromObject: params });  // Passes through the HttpParams
  }
}
