<mat-sidenav-container>
   <mat-sidenav class="asc-sidenav-menu" #sidenavMenu [mode]="menuMode" align="start" [(opened)]="isMenuOpen">
      <asc-menu (itemSelected)="menuItemSelected()"></asc-menu>
   </mat-sidenav>

   <mat-sidenav-content>
      <div class="asc-container">
         <mat-toolbar class="asc-main-toolbar" color="primary" *ngIf="!isEmbedded">
            <button mat-icon-button (click)="openMenu()" *ngIf="canShowMenuButton">
               <mat-icon>menu</mat-icon>
            </button>
            <button mat-icon-button (click)="navigateToSettings()" *ngIf="!canShowSettingsPanel && isSettingsPanelVisible">
               <mat-icon>chevron_left</mat-icon>
            </button>
            <span>{{title}}</span>
            <span class="fx-flex"></span>

            <div *ngIf="isAuthenticated; then authenticated else notAuthenticated"></div>
            <ng-template #authenticated>
               <button mat-button class="asc-login-button asc-login-button-text fx-hide-xs" [matMenuTriggerFor]="menu">
                  <span
                     class="asc-login-button-content">{{user.displayName}}<br /><small>{{user.userPrincipalName}}</small></span>
                  <mat-icon iconPositionEnd>person</mat-icon>
               </button>
               <button mat-icon-button class="asc-login-button fx-hide-gt-xs" [matMenuTriggerFor]="menu">
                  <mat-icon>person</mat-icon>
               </button>
               <mat-menu #menu="matMenu" xPosition="before">
                  <ng-template matMenuContent>
                     <div class="fx-layout-row">
                        <span class="fx-flex"></span>
                        <button mat-button color="primary" (click)="logout()">
                           <span>Abmelden</span>
                        </button>
                     </div>
                     <div class="asc-main-toolbar-menu-panel">
                        <mat-icon>person</mat-icon>
                        <div>
                           <div><b>{{user.displayName}}</b></div>
                           <div class="mat-subtitle-2">{{user.userPrincipalName}}</div>
                        </div>
                     </div>
                  </ng-template>
               </mat-menu>
            </ng-template>
            <ng-template #notAuthenticated>
               <button mat-button class="asc-login-button" (click)="login()">
                  <span>Anmelden</span>
               </button>
            </ng-template>
         </mat-toolbar>

         <asc-notification-bar></asc-notification-bar>

         <div class="asc-routing-outlet" [ngClass]="{'asc-routing-outlet-scroll-overflow': isRoutingOutletHandlingOverflow}">
            <div class="asc-title-environment" *ngIf="!isProduction">{{environment}}</div>
            <router-outlet></router-outlet>
         </div>

         <div class="asc-footer" *ngIf="!isEmbedded">
            <p *ngIf="!isDesktopLayout">ARI - ASC {{version}}</p>
         </div>
      </div>

      <div class="asc-busy-indicator" *ngIf="isBusy">
         <div class="asc-busy-indicator-control" [ngClass]="{'asc-with-text': busyMessage}">
            <mat-progress-spinner mode="indeterminate" diameter="32"></mat-progress-spinner>
            <span *ngIf="busyMessage">{{busyMessage}}</span>
         </div>
      </div>
   </mat-sidenav-content>
</mat-sidenav-container>