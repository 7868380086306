import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CORE_ROUTE_PATH } from './core.definition';

// Components
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SettingsComponent } from './views/settings/settings.component';


export const CORE_ROUTES: Routes = [
   {
      path: CORE_ROUTE_PATH.DASHBOARD,
      component: DashboardComponent
   },
   {
      path: CORE_ROUTE_PATH.SETTINGS,
      component: SettingsComponent
   }
];

export const CORE_ROUTER_MODULE: ModuleWithProviders<RouterModule> = RouterModule.forRoot(CORE_ROUTES, {
    enableTracing: true,
    initialNavigation: 'disabled'
});
