import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { BusyService } from '../../core/services/busy.service';


@Injectable()
export class NavigationService {

   constructor(
      private busyService: BusyService,
      private router: Router
   ) { }

   public navigateHome(extras?: NavigationExtras): Promise<boolean> {
      this.busyService.clearBusy();
      return this.router.navigate([''], extras);
   }

   public navigateToSettings(extras?: NavigationExtras): Promise<boolean> {
      this.busyService.clearBusy();
      return this.router.navigate(['settings'], extras);
   }
}
