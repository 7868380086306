import { Component, EventEmitter, Output } from '@angular/core';
import { ModuleManagerService } from '../../services/module-manager.service';
import { INavigationItem } from '../../model/i-navigation-item';
import { CoreService } from '../../services/core.service';
import { ModuleEntryPointDefinition as ModuleEntryPointDefinition } from '../../model/module-entry-point-definition';
import { NavigationService } from '../../services/navigation.service';

@Component({
   selector: 'asc-menu',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
   @Output() public itemSelected = new EventEmitter();
   public version?: string;
   public homeEntryPoint = new ModuleEntryPointDefinition({
      navigate: () => this.navigationService.navigateHome()
   });
   public settingsEntryPoint = new ModuleEntryPointDefinition({
      navigate: () => this.navigationService.navigateToSettings()
   });

   constructor(
      private moduleManagerService: ModuleManagerService,
      private navigationService: NavigationService,
      coreService: CoreService
   ) {
      this.version = coreService.clientConfiguration.version;
   }

   public get featureNavigationItems(): INavigationItem[] {
      return this.moduleManagerService.featureNavigationItems || [];
   }

   public get settingsNavigationItems(): INavigationItem[] {
      return this.moduleManagerService.settingsNavigationItems || [];
   }

   public navigateTo(entryPoint: ModuleEntryPointDefinition) {
      this.itemSelected.emit();

      if (entryPoint.navigate) {
         entryPoint.navigate();
      }
   }
}
