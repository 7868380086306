import { Component } from '@angular/core';
import { ModuleManagerService } from '../../services/module-manager.service';
import { INavigationItem } from '../../model/i-navigation-item';
import { ModuleEntryPointDefinition } from '../../model/module-entry-point-definition';

@Component({
   selector: 'asc-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

   constructor(
      private moduleManagerService: ModuleManagerService
   ) { }

   public get featureNavigationItems(): INavigationItem[] {
      return this.moduleManagerService.featureNavigationItems || [];
   }

   public navigateTo(entryPoint: ModuleEntryPointDefinition) {
      if (entryPoint) {
         entryPoint.navigate();
      }
   }
}
