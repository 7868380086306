import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 2000;

@Injectable()
export class HttpRequestCachingService {

  private cache = new Map<string, { url: string; response: HttpResponse<any>; lastRead: number }>();

  public get(req: HttpRequest<any>): HttpResponse<any> | undefined {

    this.deleteExpiredEntries();

    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    return (cached) ? cached.response : undefined;
  }

  public put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url: url, response: response, lastRead: Date.now() };
    this.cache.set(url, entry);

    this.deleteExpiredEntries();
  }

  public deleteExpiredEntries(): void {
    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
