import { Component, EventEmitter, Output } from '@angular/core';
import { ModuleManagerService } from '../../services/module-manager.service';
import { INavigationItem } from '../../model/i-navigation-item';
import { ModuleEntryPointDefinition } from '../../model/module-entry-point-definition';

@Component({
   selector: 'asc-settings-menu',
   templateUrl: './settings-menu.component.html',
   styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent {
   @Output() public itemSelected = new EventEmitter();

   constructor(
      private moduleManagerService: ModuleManagerService
   ) { }

   public get settingsNavigationItems(): INavigationItem[] {
      return this.moduleManagerService.settingsNavigationItems || [];
   }

   public navigateTo(entryPoint: ModuleEntryPointDefinition) {
      this.itemSelected.emit();

      if (entryPoint.navigate) {
         entryPoint.navigate();
      }
   }
}
