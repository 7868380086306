import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpRequestCachingService } from './http-request-caching.service';
import { InterceptorHttpParams } from './interceptor-http-params';

@Injectable()
export class HttpRequestCachingInterceptor implements HttpInterceptor {
    private pendingRequests = new Map<string, Subject<HttpEvent<any>>[]>();


    constructor(private cache: HttpRequestCachingService) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clean cache
        this.cache.deleteExpiredEntries();

        if (req.params instanceof InterceptorHttpParams && req.params.interceptorConfig.cacheRequest) {
            // Use caching on GET requests

            // Try getting cached response
            const response = this.cache.get(req);
            if (response) {
                return of(response);
            }

            // Flag request as pending
            const url = req.urlWithParams;
            let pending = this.pendingRequests.get(url);
            if (!pending) {
                pending = [];
                this.pendingRequests.set(url, pending);
            }

            // Prepare new observable waiting for the response
            const pendingObservable = new Subject<HttpEvent<any>>();
            pending.push(pendingObservable);

            // Send request to server
            if (pending.length === 1) {
                return next.handle(req).pipe(
                    tap(event => {
                        // Cache result
                        if (event instanceof HttpResponse) {
                            this.cache.put(req, event);

                            // Complete pending requests
                            const pendingRequest = this.pendingRequests.get(url);
                            if (pendingRequest) {
                                pendingRequest.forEach(subscriber => {
                                    subscriber.next(event);
                                    subscriber.complete();
                                });
                                this.pendingRequests.delete(url);
                            }
                        }
                    })
                );
            }
            else {
                // Return new observable waiting for the response
                return pendingObservable;
            }
        }
        else {
            // Don't use caching on other requests
            return next.handle(req);
        }
    }
}
