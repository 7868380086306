import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { SessionService } from './session.service';

@Injectable()
export class DataSourceAvailabilityInterceptor implements HttpInterceptor {

    static readonly HEADER_ASC_DATA_SOURCE = 'x-asc-data-source';

    constructor(
        private httpClient: HttpClient,
        private sessionService: SessionService) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Set headers
        if (this.sessionService.dataSource) {
            const headers = {};
            (<any>headers)[DataSourceAvailabilityInterceptor.HEADER_ASC_DATA_SOURCE] = this.sessionService.dataSource;
            req = req.clone({ setHeaders: headers });
        }

        // Send request
        return next
            .handle(req)
            .pipe(
                tap(evt => {
                    // Check for Response
                    if (evt instanceof HttpResponse) {
                        const dataSource = evt.headers.get(DataSourceAvailabilityInterceptor.HEADER_ASC_DATA_SOURCE);
                        this.sessionService.checkDataSourceAvailability(dataSource);
                    }
                }),
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        const dataSource = err.headers.get(DataSourceAvailabilityInterceptor.HEADER_ASC_DATA_SOURCE);
                        this.sessionService.checkDataSourceAvailability(dataSource);
                    }

                    throw err;
                })
            );
    }
}
